<template>
  <div id="lowCodePlatform">
    <!-- lowCodePlate首页头部 -->
    <div class="lowCodeHeader">
      <!-- 头部组件 -->
      <Header></Header>
      <div class="texts">
        <div class="text1">三象低代码平台</div>
        <div class="text2"></div>
        <div class="text3">
          三象低代码平台通过组件化、配置化的设计思想，将与应用无关的逻辑全部封装，前后端分离，按需开发，支持与第三方前端和后台的对接，根据需求灵活、快速开发。
        </div>
      </div>
    </div>
    <!-- lowCodePlate中间内容 -->
    <div class="lowCodeCenter">
      <!-- 我们的优势 -->
      <div class="goodsness">
        <div class="goodsnessTitle">
          我们的优势
        </div>
        <!-- lowCodePlate中间内容1 -->
        <div class="goodnessContentOne">
          <div class="ContentOneLeft">
            <div class="goodsConOneTit">规则配置化，灵活应对业务变化</div>
            <div class="goodsConOneContent">
              基于平台所带规则引擎，轻松完成业务表单关系设计、触发动作设计、业务操作设计，更敏捷响应业务变化。
            </div>
          </div>
          <div class="ContentOneRight">
            <div class="ContentOneNum">
              1
            </div>
          </div>
        </div>
        <!-- lowCodePlate中间内容2 -->
        <div class="goodnessContentTwo">
          <div class="ContentTwoTop">
            <div class="ContentTwoLeft">
              <div class="goodsConTwoTit">精细化权限设计</div>
              <div class="goodsConTwoContent">
                支持角色权限、数据管理、数据权限、字段权限等精细化管理；确保不同场景下的数据与应用安全。
              </div>
            </div>
            <div class="ContentTwoRight">
              <div class="ContentTwoNum">
                2
              </div>
            </div>
          </div>
          <ul class="ContentTwoUnder">
            <li v-for="(item, index) in ContentTwoUnder" :key="index">
              <div class="thread"></div>
              <div class="ContentTwoUnderCon">
                <div class="ContentTwoUnConTit">{{ item.tit }}</div>
                <div class="ContentTwoUnConText">
                  {{ item.text }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 开发流程 -->
      <div class="worklFlow">
        <div class="worklFlowTit">
          开发流程
        </div>
        <div class="worklFlowPro">
          平台涵盖了从开发，测试，发布，运维全套敏捷流程
        </div>
        <ul class="worklFlowStep">
          <li v-for="(item, index) in worklFlowStep" :key="index">
            <div class="stepSlogan">{{ item.stepNum }}</div>
            <div class="stepTit">{{ item.steTit }}</div>
            <div class="stepPic">
              <img :src="item.stepImg" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <!-- 支持终端 -->
      <div class="support">
        <div class="supportTit">
          支持终端
        </div>
        <div class="supportContent">
          <div class="supportConLeft">
            <div class="supportConLeTit">多端协调使用</div>
            <div class="supportConLeText">
              平台支持桌面端、Web端、小程序平台开发，适用于服务业、制造业等生产场景。
            </div>
          </div>
          <div class="supportConRight">
            <ul>
              <li v-for="(item, index) in supportConRight" :key="index">
                <img :src="item.supportPic" alt="" />
                <div class="supportConRitext">{{ item.text }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    <el-backtop
      ><img
        src="~assets/img/AboutUs/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

export default {
  name: "LowCodePlatform",
  data() {
    return {
      ContentTwoUnder: [
        {
          tit: "字段权限",
          text:
            "指具体表单中的字段权限。前提需要拥有该表单的阅读权限,可对字段进行只读、编辑、隐藏更细颗粒的权限控制。"
        },
        {
          tit: "数据权限",
          text:
            "指具体某一条数据的权限。指可阅读者、可编辑者、仅需要默认权限就可以对数据进行阅读、编辑操作。"
        },
        {
          tit: "数据管理",
          text:
            "指和数据相关的角色权限。为系统设计时预置，管理员可根据业务相关，为本部门、本机构、本人、自定义(该业务的数据)做分级权限。"
        },
        {
          tit: "角色权限",
          text:
            "指角色权限。为系统设计时预置，为系统级权限，通常和数据无关，可分为三员管理权限和分级权限。"
        }
      ],
      worklFlowStep: [
        {
          stepNum: "Step  01",
          steTit: "开发",
          stepImg: require("assets/img/lowCodePlatform/icon-开发@2x.png")
        },
        {
          stepNum: "Step  02",
          steTit: "测试",
          stepImg: require("assets/img/lowCodePlatform/icon-测试@2x.png")
        },
        {
          stepNum: "Step  03",
          steTit: "发布",
          stepImg: require("assets/img/lowCodePlatform/icon-发布@2x.png")
        },
        {
          stepNum: "Step  04",
          steTit: "运维",
          stepImg: require("assets/img/lowCodePlatform/icon-运维@2x.png")
        }
      ],
      supportConRight: [
        {
          supportPic: require("assets/img/lowCodePlatform/web端@2x.png"),
          text: "WEB 端"
        },
        {
          supportPic: require("assets/img/lowCodePlatform/小程序@2x.png"),
          text: "微信小程序"
        },
        {
          supportPic: require("assets/img/lowCodePlatform/工位电脑@2x.png"),
          text: "工位电脑"
        }
      ]
    };
  },
  components: {
    Header,
    Footer

  }
};
</script>
<style lang="less" scoped>
#lowCodePlatform {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // lowCodePlate头部样式
  .lowCodeHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/lowCodePlatform/banner@2x.png") no-repeat;
  background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
    // margin: 0 auto;
    .texts {
      margin-left: 900px;
      .text1 {
      
        height: 38px;
        font-size: 38px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        line-height: 25px;
        color: #1e90ff;
        opacity: 1;
        margin-top: 151px;
      }
      .text2 {
        width: 100px;
        height: 10px;
        background: #1e90ff;
        opacity: 1;
        border-radius: 5px;
        margin-top: 11px;
      }
      .text3 {
        width: 489px;
        height: 143px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 36px;
        color: rgba(51, 51, 51, 0.65);
        opacity: 1;
        margin-top: 20px;
      }
    }
  }
  // lowCodePlate中间样式
  .lowCodeCenter {
    width: 1220px;
    margin: 0 auto;
    // 我们的优势模块样式
    .goodsness {
      .goodsnessTitle {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        text-align: center;
        margin-top: 80px;
      }
      // lowCodePlate中间内容1样式
      .goodnessContentOne {
        width: 1220px;
        height: 110px;
        background: #ffffff;
        opacity: 1;
        border-radius: 10px;
        margin-top: 40px;
        overflow: hidden;
        display: flex;
        .ContentOneLeft {
          margin-left: 90px;
          .goodsConOneTit {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #1e90ff;
            opacity: 1;
            margin-top: 30px;
          }
          .goodsConOneContent {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            opacity: 1;
            margin-top: 20px;
          }
        }
        .ContentOneRight {
          margin: 30px 90px 30px 317px;
          .ContentOneNum {
            width: 50px;
            height: 50px;
            background: #e7f3fe;
            border-radius: 50%;
            opacity: 1;
            text-align: center;
            line-height: 50px;
            font-size: 46px;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #1e90ff;
            opacity: 1;
          }
        }
      }
      // lowCodePlate中间内容2样式
      .goodnessContentTwo {
        width: 1220px;
        background: #ffffff;
        opacity: 1;
        border-radius: 10px;
        margin-top: 20px;
        overflow: hidden;
        .ContentTwoTop {
          display: flex;
          .ContentTwoLeft {
            margin-left: 80px;
            .goodsConTwoTit {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #1e90ff;
              opacity: 1;
              margin-top: 30px;
            }
            .goodsConTwoContent {
              width: 622px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              opacity: 1;
              margin-top: 20px;
            }
          }
          .ContentTwoRight {
            margin: 30px 90px 30px 380px;
            .ContentTwoNum {
              width: 50px;
              height: 50px;
              background: #e7f3fe;
              border-radius: 50%;
              opacity: 1;
              text-align: center;
              line-height: 50px;
              font-size: 46px;
              font-family: YouSheBiaoTiHei;
              font-weight: 400;
              color: #1e90ff;
              opacity: 1;
            }
          }
        }
        .ContentTwoUnder {
          padding: 20px 0 0px 80px;
          margin: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          li {
            display: flex;
            margin-right: 80px;
            .thread {
              width: 6px;
              height: 74px;
              background: #1e90ff;
              opacity: 1;
              border-radius: 3px;
              margin-bottom: 40px;
            }
            .ContentTwoUnderCon {
              margin-left: 20px;
              .ContentTwoUnConTit {
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                opacity: 1;
              }
              .ContentTwoUnConText {
                width: 464px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 28px;
                color: rgba(51, 51, 51, 0.65);
                opacity: 1;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
    // 开发流程模块样式
    .worklFlow {
      margin-top: 80px;
      .worklFlowTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        text-align: center;
      }
      .worklFlowPro {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.85);
        opacity: 1;
        text-align: center;
        margin-top: 28px;
      }
      .worklFlowStep {
        width: 1220px;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        padding: 0px;
        li {
          width: 240px;
          height: 300px;
          background: #fff;
          overflow: hidden;
        }
        .stepSlogan {
          width: 125px;
          height: 40px;
          background: #e7f3fe;
          opacity: 1;
          border-radius: 0px 20px 20px 0px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 40px;
          text-align: center;
          color: #1e90ff;
          margin-top: 32px;
        }
        .stepTit {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.85);
          text-align: center;
          margin-top: 24px;
        }
        .stepPic {
          text-align: center;

          img {
            width: 130px;
            height: 130px;
            margin-top: 20px;
          }
        }
      }
    }
    // 支持终端模块样式
    .support {
      margin: 80px auto;
      .supportTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        text-align: center;
      }
      .supportContent {
        margin-top: 28px;
        width: 1220px;
        height: 268px;
        background: url("~assets/img/lowCodePlatform/BG-支持终端@2x.png");
        background-size: 1220px 268px;
        background-position: center;
        display: flex;
        .supportConLeft {
          margin-left: 71px;
          .supportConLeTit {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            line-height: 26px;
            color: #1e90ff;
            opacity: 1;
            margin-top: 70px;
          }
          .supportConLeText {
            width: 297px;
            height: 40px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 26px;
            color: #333333;
            margin-top: 30px;
          }
        }
        .supportConRight {
          margin: 30px 0 0px 47px;
          ul {
            display: flex;
            li {
              text-align: center;
               margin-left: -5px;
              img {
                width: 240px;
                height: 109px;
                &:nth-child(2) {
                  width: 239px;
                  height: 108px;
                }
                &:nth-child(2) {
                  width: 237px;
                  height: 108px;
                 
                }
              }

              .supportConRitext {
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: rgba(51, 51, 51, 0.65);
                margin-top: 43px;
              }
            }
          }
        }
      }
    }
  }
}
.el-backtop{
    img{
        width: 30px;
        height: 30px;
    }
}
</style>
